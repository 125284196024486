/*
  This example requires Tailwind CSS v2.0+

  This example requires some changes to your config:

  ```
  // tailwind.config.js
  module.exports = {
    // ...
    plugins: [
      // ...
      require('@tailwindcss/forms'),
    ]
  }
  ```
*/
import React from "react";
import { Helmet } from "react-helmet";
export default function Newsletter() {
  return (
    <div className="bg-white">
      <div className="max-w-7xl mx-auto px-4 py-12 sm:px-6 lg:py-16 lg:px-8">
        <div className="px-6 py-6 bg-indigo-700 rounded-lg md:py-12 md:px-12 lg:py-16 lg:px-16 xl:flex xl:items-center">
          <div className="xl:w-0 xl:flex-1">
            <h2 className="text-2xl font-extrabold tracking-tight text-white sm:text-3xl">
              Want news and updates?
            </h2>
            <p className="mt-3 max-w-3xl text-lg leading-6 text-indigo-200">
              Sign up for our newsletter to stay up to date.
            </p>
          </div>
          <div className="mt-8 sm:w-full sm:max-w-md xl:mt-0 xl:ml-8">
            <div className="emailoctopus-form-wrapper emailoctopus-form-default null">
              <p className="emailoctopus-success-message text-sm text-indigo-200"></p>
              <p className="emailoctopus-error-message text-sm text-indigo-200"></p>
            </div>
            <form
              action="https://emailoctopus.com/lists/9bbc0117-a7e9-11eb-a3d0-06b4694bee2a/members/embedded/1.3/add"
        method="post"
        data-message-success="Thanks for subscribing!"
        data-message-missing-email-address="Your email address is required."
        data-message-invalid-email-address="Your email address looks incorrect, please try again."
        data-message-bot-submission-error="This doesn't look like a human submission."
        data-message-consent-required="Please check the checkbox to indicate your consent."
        data-message-invalid-parameters-error="This form has missing or invalid fields."
        data-message-unknown-error="Sorry, an unknown error has occurred. Please try again later."
        class="emailoctopus-form sm:flex"
            >
              <label htmlFor="emailAddress" className="sr-only">
                Email address
              </label>
              <input
                id="field_0"
                name="field_0"
                type="email"
                required="required"
                className="w-full border-white px-5 py-3 placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white rounded-md"
                placeholder="Enter your email"
              />
              <button
                type="submit"
                className="mt-3 w-full flex items-center justify-center px-5 py-3 border border-transparent shadow text-base font-medium rounded-md text-white bg-indigo-500 hover:bg-indigo-400 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-indigo-700 focus:ring-white sm:mt-0 sm:ml-3 sm:w-auto sm:flex-shrink-0"
              >
                Notify me
              </button>

              <div aria-hidden="true" className="emailoctopus-form-row-hp">
                <input type="hidden"
                       name="hpc4b27b6e-eb38-11e9-be00-06b4694bee2a"
                       tabIndex="-1"
                       autoComplete="nope"
                />
              </div>
              <div className="emailoctopus-form-row-subscribe hidden">
                <input type="hidden" name="successRedirectUrl" />
              </div>
            </form>

            <p className="mt-3 text-sm text-indigo-200">
              We care about the protection of your data. Read our{" "}
              <a
                href="/privacy/"
                target="_blank"
                className="text-white font-medium underline"
              >
                Privacy Policy.
              </a>
            </p>

          </div>

        </div>
      </div>
      <Helmet>
        <script src="https://emailoctopus.com/bundles/emailoctopuslist/js/1.6/form-embed.js"></script>
      </Helmet>
    </div>
  );
}
