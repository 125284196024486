/* This example requires Tailwind CSS v2.0+ */
import React from "react";
import Feature from "../components/feature";
import Tech from "../components/tech";
import Stat from "../components/stat";
import Newsletter from "../components/newsletter";
import Testimony from "../components/testimony";
import { graphql, useStaticQuery } from "gatsby";
import Layout from "../components/layout";
import Hero from "../components/hero";
import Ctahome from "../components/cta-home";

const HomePage = () => {
  const data = useStaticQuery(query);

  return (
    <Layout seo={data.strapiHomepage.seo}>
      <Hero />
      <Tech />
      <Feature />
      <Stat />
        <Ctahome />
        <Newsletter />
    </Layout>
  );
};

const query = graphql`
  query {
    strapiHomepage {
      seo {
        metaTitle
        metaDescription
        shareImage {
          publicURL
        }
      }
    }
  }
`;

export default HomePage;
